export function downloadCSV(data, headers, name) {
    const headerRow = headers.join(',');

    const csvRows = data.map(row => {
        return headers.map(field => {
            const value = row[field];
            if (value !== undefined && value !== null) {
                let stringValue = String(value);
                stringValue = stringValue.replace(/"/g, '""');
                if (stringValue.includes(',') || stringValue.includes('\n') || stringValue.includes('"')) {
                    stringValue = `"${stringValue}"`;
                }
                return stringValue;
            }
            return ''; // Return empty string for null or undefined values
        }).join(',');
    });

    // Combine the header and rows, then prepare for download
    const csvContent = [headerRow, ...csvRows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name}.csv`;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
}