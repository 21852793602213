// Create a Google Sheet

import { showError } from '../../shared/error';
import { getGoogleToken } from './auth.js';

export async function createSpreadsheet(title, headers, data) {
    const token = await getGoogleToken();

    // First, create the spreadsheet
    const createUrl = 'https://sheets.googleapis.com/v4/spreadsheets';
    const createOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            properties: {
                title: title
            },
            sheets: [{
                properties: {
                    title: 'Sheet1'
                }
            }]
        })
    };

    try {
        const createResponse = await fetch(createUrl, createOptions);
        if (!createResponse.ok) {
            showError('3001', 'There was an error creating the spreadsheet.', 'Please try again later.', createResponse.status, false, true);
            return null;
        }
        const spreadsheet = await createResponse.json();
        console.log('Created Spreadsheet ID:', spreadsheet.spreadsheetId);

        // Now, populate the spreadsheet with headers and data
        // Calculate the ending cell based on headers length
        let endColumn = String.fromCharCode(65 + headers.length - 1);  // Assumes less than 26 columns
        let endRow = data.length + 1;  // Account for header plus data rows
        const values = [headers, ...data];
        const range = `Sheet1!A1:${endColumn}${endRow}`;
        const updateUrl = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheet.spreadsheetId}/values/${range}?valueInputOption=USER_ENTERED`;
        const updateOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                range: range,
                majorDimension: 'ROWS',
                values: values
            })
        };

        const updateResponse = await fetch(updateUrl, updateOptions);
        if (!updateResponse.ok) {
            showError('3002', 'There was an error populating the spreadsheet.', 'Please try again later.', updateResponse.status, false, true);
            return null;
        }
        console.log('Spreadsheet populated successfully.');
        return spreadsheet;
    } catch (error) {
        console.error('Error in spreadsheet operation:', error);
        showError('3003', 'There was an error creating the spreadsheet.', 'Please try again later.', false, false, true);
        return null;
    }
}
