import { Timestamp, addDoc, collection, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../shared/firebase.js';
import { closeModal } from '../../shared/modal';
import { showError } from '../../shared/error.js';
import { loadUsersManager } from '../../manager/users.js';
import { deleteItemsForType, loadItems, returnItem } from '../items.js';
import { delay } from '../../shared/delay.js';

// delete a user or group
export async function deleteObject() {
    console.log(`deleting ${window.deletingObjectType} ${window.deletingObjectID}`);

    // check the type
    switch (window.deletingObjectType) {
    case 'user':
        // delete the user
        await deleteUser(window.deletingObjectID);
        document.getElementById(window.deletingObjectID).remove();
        break;
    case 'invitedUser':
        // delete the invited user
        await deleteInvitedUser(window.deletingObjectID);
        document.getElementById(window.deletingObjectID).remove();
        break;
    case 'event':
        // delete the event
        await deleteEvent(window.deletingObjectID);
        break;
    case 'item':
        // delete the item
        await deleteItem(window.deletingObjectID);
        break;
    case 'itemType':
        // delete the item type
        await deleteItemType(window.deletingObjectID);
        break;
    case 'user-manager':
        // delete the user
        await deleteUser(window.deletingObjectID);
        await loadUsersManager();
        break;
    case 'invitedUser-manager':
        // delete the invited user
        await deleteInvitedUser(window.deletingObjectID);
        await loadUsersManager();
        break;
    default:
        console.log('unknown type');
        break;
    }

    // clear the deleting object
    window.deletingObjectType = null;
    window.deletingObjectID = null;

    // close the modal
    closeModal(document.getElementById('confirm-delete-modal'));
    // reset the message
    document.getElementById('confirm-delete-message').innerText = 'This will be permanently deleted.';
}

// delete an item
async function deleteItem(ID) {
    console.log(`deleting item ${ID}`);
    window.deferItemsAutoReload = true;
    // set deleted to true in the item doc
    const itemRef = doc(db, 'domains', window.userDomain, 'items', ID);
    const itemDoc = await getDoc(itemRef);
    // if the item is loaned, return it
    if (itemDoc.data().loaned) {
        await returnItem(ID);
    }

    await updateDoc(itemRef, {
        deleted: true
    });
    console.log('Item deleted');
    // create a history record for 'deleted'
    const historyRef = collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'itemHistory');
    await addDoc(historyRef, {
        action: 'deleted',
        item: ID,
        student: null,
        timestamp: Timestamp.now(),
        admin: window.userID,
        archived: false
    });
    // remove from the table
    document.getElementById(`item-${ID}`).remove();
    window.deferItemsAutoReload = false;
}

async function deleteItemType(ID) {
    console.log(`deleting item type ${ID}`);
    window.deferItemsAutoReload = true;
    const itemRef = doc(db, 'domains', window.userDomain, 'itemTypes', ID);
    await updateDoc(itemRef, {
        deleted: true
    });
    console.log('Item type deleted');
    // delete all items of this type
    await deleteItemsForType(ID);
    // wait 500ms for the items to be deleted
    await delay(500);
    // fully reload the item table
    await loadItems();
    window.deferItemsAutoReload = false;
}

// delete an event
async function deleteEvent(ID) {
    console.log(`deleting event ${ID}`);
    // delete the event
    await deleteDoc(doc(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'events', ID));
    console.log('Event deleted');
    // remove from the table
    document.getElementById(`event-${ID}`).remove();
}

async function deleteUser(ID) {
    console.log(`deleting user ${ID}`);

    // check this isn't the current user
    if (ID === window.userID) {
        console.error('Cannot delete the current user');
        showError('3018', 'Unable to Delete User', 'You cannot delete your own account.', null, false, false);
        return;
    }

    //  update 'status' to 'deleted'
    await updateDoc(doc(db, 'users', ID), {
        status: 'deleted'
    });
    console.log('User updated');
}

// delete an invited user
async function deleteInvitedUser(ID) {
    console.log(`deleting invited user ${ID}`);

    //  update 'status' to 'deleted'
    await updateDoc(doc(db, 'users', 'invitedUsers', 'users', ID), {
        status: 'deleted'
    });
}
