import { jsPDF } from 'jspdf';
// eslint-disable-next-line no-unused-vars
import autoTable from 'jspdf-autotable';

export function downloadPDF(title, description, headers, dataObjects, filename) {
    const doc = new jsPDF();

    // Set Title
    doc.setFontSize(20);
    doc.text(title, 20, 20);

    // Set Description
    doc.setFontSize(12);
    doc.text(description, 20, 30);

    // Convert dataObjects (array of objects) to data (array of arrays)
    const data = dataObjects.map(obj => headers.map(header => obj[header] || ''));

    // Generate Table
    if (headers.length > 0 && data.length > 0) {
        doc.autoTable({
            startY: 40,
            head: [headers],
            body: data,
            theme: 'striped',
            headStyles: { fillColor: [22, 160, 133] },
            bodyStyles: { fillColor: [255, 255, 255] },
            alternateRowStyles: { fillColor: [240, 240, 240] }
        });
    } else {
        doc.text('No data available to display.', 20, 50);
    }

    // Save the PDF
    doc.save(`${filename}.pdf`);
}