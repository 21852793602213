import { db } from '../shared/firebase.js';
import { collection, getDocs, query, orderBy, getDoc, doc, addDoc, where } from 'firebase/firestore';
import { openModal } from '../shared/modal';
import { showError } from '../shared/error';
import { validateEmail } from '../admin/utils/email.js';
import { closeModal } from '../shared/modal';
import { delay } from '../shared/delay.js';

var newUserSelectedDomain = null;

// load users
export async function loadUsersManager() {
    console.log('Loading users...');
    // add the loading row
    const tableBody = document.querySelector('#users-table tbody');
    // clear the table
    tableBody.innerHTML = '';

    // get all the users
    const usersRef = query(collection(db, 'users'), orderBy('name'));
    const usersSnapshot = await getDocs(usersRef);
    // clear the table
    document.querySelector('#users tbody').innerHTML = '';

    // if there are no users
    if (usersSnapshot.size == 0) {
        console.log('No users found');
        const tableBody = document.querySelector('#users-table tbody');
        const row = document.createElement('tr');
        row.innerHTML = `
               <td colspan="6" style="text-align: center;">No users found</td>
               `;
        tableBody.appendChild(row);
    } else {
        // there are users
        console.log('Users found');
        // sort the users by name
        usersSnapshot.forEach(async (docu) => {
            // add to the table
            await addUserToTable(docu.data(), docu.id);
        });
    }


    // get all invited users
    // users/invitedUsers/users/{userID}
    const invitedUsersRef = query(collection(db, 'users', 'invitedUsers', 'users'));
    const invitedUsersSnapshot = await getDocs(invitedUsersRef);
    // clear the table
    document.querySelector('#invited-users-table tbody').innerHTML = '';

    // if there are no invited users
    if (invitedUsersSnapshot.size == 0) {
        console.log('No invited users found');
        const tableBody = document.querySelector('#invited-users-table tbody');
        const row = document.createElement('tr');
        row.innerHTML = `
               <td colspan="5" style="text-align: center;">No invited users found</td>
               `;
        tableBody.appendChild(row);
    } else {
        // there are invited users
        console.log('Invited users found');
        // sort the users by name
        invitedUsersSnapshot.forEach(async (docu) => {
            // add to the table
            await addInvitedUserToTable(docu.data(), docu.id);
        });
    }

    console.log('Users loaded');
}

// add an invited user to the table
async function addInvitedUserToTable(userData, ID) {

    const email = userData.email;
    var role = userData.role;
    var status = userData.status;

    var domainName = '-';

    // if the user is not a super admin, check if they have a domain and get the name
    if (role !== 'superadmin') {
        const domainRef = doc(db, 'domains', userData.domain);
        const domainDoc = await getDoc(domainRef);
        if (domainDoc.exists()) {
            domainName = domainDoc.data().name;
        }
    }

    // format status
    // created, email_sent
    if (status == 'created') {
        status = 'Created';
    } else if (status == 'email_sent') {
        status = 'Email Sent';
    } else if (status == 'completed') {
        status = 'Completed';
    } else if (status == 'deleted') {
        status = 'Cancelled';
    }

    // format role
    if (role == 'superadmin') {
        role = 'Super Admin';
    } else if (role == 'domainadmin') {
        role = 'Domain Admin';
    } else if (role == 'badgeadmin') {
        role = 'Badge Admin';
    } else if (role == 'viewer') {
        role = 'Viewer';
    } else if (role == 'firemarshal') {
        role = 'Fire Marshal';
    } else if (role == 'kiosk') {
        role = 'Kiosk';
    }

    // get which user invited the user
    const invitedByRef = doc(db, 'users', userData.invitedBy);
    const invitedByDoc = await getDoc(invitedByRef);
    const invitedByName = invitedByDoc.data().name;

    const tableBody = document.querySelector('#invited-users-table tbody');
    const row = document.createElement('tr');
    row.id = ID;

    row.innerHTML = `
    <td>${email}</td>
    <td>${role}</td>
    <td>${domainName}</td>
    <td>${status}</td>
    <td>${invitedByName}</td>
    <td><a id="delete-invited-user-btn-${ID}" style='color: red;'>Cancel Invite</a></td>
    `;

    tableBody.appendChild(row);
    // event listener for delete user button
    document.getElementById(`delete-invited-user-btn-${ID}`).addEventListener('mousedown', async () => {
        console.log(`delete user button for invited user ${ID} has been clicked`);
        // set the global variable
        window.deletingObjectID = ID;
        window.deletingObjectType = 'invitedUser-manager';
        // show the delete confirm modal
        openModal(document.getElementById('confirm-delete-modal'));
    });
}
// add a user to the table
async function addUserToTable(userData, ID) {
    const name = userData.name;
    var role = userData.role;

    // this is a timestamp - convert to date
    const lastLogin = userData.lastLogin.toDate().toLocaleString();
    const email = userData.email;
    var status = userData.status || 'Active';

    var domainName = '-';

    // if the user is not a super admin, check if they have a domain and get the name
    if (role !== 'superadmin') {
        const domainRef = doc(db, 'domains', userData.domain);
        const domainDoc = await getDoc(domainRef);
        if (domainDoc.exists()) {
            domainName = domainDoc.data().name;
        }
    }

    // format role
    if (role == 'superadmin') {
        role = 'Super Admin';
    } else if (role == 'domainadmin') {
        role = 'Domain Admin';
    } else if (role == 'badgeadmin') {
        role = 'Badge Admin';
    } else if (role == 'viewer') {
        role = 'Viewer';
    } else if (role == 'firemarshal') {
        role = 'Fire Marshal';
    } else if (role == 'kiosk') {
        return; // don't add kiosk users to the table
    }

    // format staus = active or deleted
    if (status == 'deleted') {
        status = 'Deleted';
    } else {
        status = 'Active';
    }


    const tableBody = document.querySelector('#users tbody');
    const row = document.createElement('tr');
    row.id = ID;

    // name, email, role, domain, last login, delete button
    // if the user is the current user, dont add the delete button
    if (ID == window.userID) {
        row.innerHTML = `
        <td>${name}</td>
        <td>${email}</td>
        <td>${role}</td>
        <td>${domainName}</td>
        <td>${status}</td>
        <td>${lastLogin}</td>
        <td>-</td>
        `;

        tableBody.appendChild(row);
    } else {
        row.innerHTML = `
        <td>${name}</td>
        <td>${email}</td>
        <td>${role}</td>
        <td>${domainName}</td>
        <td>${status}</td>
        <td>${lastLogin}</td>
        <td><a id="delete-user-btn-${ID}" style='color: red;'>Disable User</a></td>
        `;

        tableBody.appendChild(row);

        // add event listener for delete user button
        document.getElementById(`delete-user-btn-${ID}`).addEventListener('mousedown', async () => {
            console.log(`delete user button for user ${ID} has been clicked`);
            // set the global variable
            window.deletingObjectID = ID;
            window.deletingObjectType = 'user-manager';
            // show the delete confirm modal
            openModal(document.getElementById('confirm-delete-modal'));

        });
    }
}

// called when the role dropdown is changed
export async function handleNewUserRoleChange() {
    console.log('handling new user role change...');
    // get the selected role
    const role = document.querySelector('input[name="role"]:checked').value;
    // update the summary
    await updateNewUserRoleSummary(role);
}

// update the summary of ROLE
async function updateNewUserRoleSummary(role) {
    console.log('updating summary of new user role...');
    // get the summary
    const summary = document.getElementById('newUser-roleSummary');
    // check if there is a role
    if (role) {
        // there is a role
        // set the colour
        summary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');
        // set the summary to the role
        summary.innerHTML = role;
    } else {
        // there is no role
        // set the summary to 'select role...'
        // set the colour to the default
        summary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--form-element-placeholder-color');
        summary.innerHTML = 'Select role...';
    }
}


// fill the dropdowns
export async function prepareCreateUserModal() {

    // clear modal content
    document.getElementById('new-user-email').value = '';
    document.getElementById('newUser-domain').innerHTML = 'Select domain...';
    document.getElementById('newUser-roleSummary').innerHTML = 'Select role...';
    document.getElementById('newUser-domainOptions').innerHTML = '';
    // reset the colours
    document.getElementById('newUser-domain').style.color = getComputedStyle(document.documentElement).getPropertyValue('--form-element-placeholder-color');
    document.getElementById('newUser-roleSummary').style.color = getComputedStyle(document.documentElement).getPropertyValue('--form-element-placeholder-color');
    // uncheck all the role radio buttons
    document.getElementById('newUser-roleOption-domainadmin').getElementsByTagName('input')[0].checked = false;
    document.getElementById('newUser-roleOption-superadmin').getElementsByTagName('input')[0].checked = false;
    document.getElementById('newUser-roleOption-badgeadmin').getElementsByTagName('input')[0].checked = false;
    document.getElementById('newUser-roleOption-viewer').getElementsByTagName('input')[0].checked = false;
    document.getElementById('newUser-roleOption-firemarshal').getElementsByTagName('input')[0].checked = false;

    // get all the domains
    const domainsRef = query(collection(db, 'domains'), orderBy('name'));
    const domainsSnapshot = await getDocs(domainsRef);
    console.log('Domains:', domainsSnapshot.size);

    // configure the domains dropdown
    // clear the dropdown
    document.getElementById('newUser-domainOptions').innerHTML = '';
    // sort the domains by name
    domainsSnapshot.forEach(async (docu) => {
        // add to the dropdown - newUser-domainOptions
        // this will include an event listener, to show the groups for that domain
        await addDomainToNewUserModalDropdown(docu.data(), docu.id);
    });
}

// add domains to the new user modal dropdown
async function addDomainToNewUserModalDropdown(domainData, ID) {
    const name = domainData.name;

    const dropdown = document.getElementById('newUser-domainOptions');

    const li = document.createElement('li');
    li.id = ID;

    li.innerHTML = `
    <label>
        <input type="radio" id="new-user-domain-radio-${ID}" name="domain"
            value="${name}">
        ${name}
    </label>
    `;
    dropdown.appendChild(li);

    // add event listener for button
    document.getElementById(`new-user-domain-radio-${ID}`).addEventListener('mousedown', async () => {
        console.log(`radio button for domain ${ID} has been clicked`);
        // update the selected domain
        await updateNewUserModalSelectedDomain(ID);
    });
}

// update the selected domain
async function updateNewUserModalSelectedDomain(domainID) {
    // set the global variable
    newUserSelectedDomain = domainID;
    console.log(`checked domain: ${domainID}`);

    // update the summary
    updateNewUserModalDomainSummary();
}

// update the summary of DOMAIN - a user can only be in one domain
async function updateNewUserModalDomainSummary() {
    console.log('updating summary of new user domain...');
    // get the summary
    const summary = document.getElementById('newUser-domain');
    // check if there is a domain
    if (newUserSelectedDomain.length != 0) {
        // there is a domain
        // set the colour
        summary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');
        // get the name of the domain - the value of the radio button
        const radio = document.getElementById(`new-user-domain-radio-${newUserSelectedDomain}`);
        const domainName = radio.value;
        // set the summary to the domain
        summary.innerHTML = domainName;
    } else {
        // there is no domain
        // set the summary to 'select domain...'
        // set the colour to the default
        summary.style.color = getComputedStyle(document.documentElement).getPropertyValue('--form-element-placeholder-color');
        summary.innerHTML = 'Select domain...';
    }
}


export async function createNewUser() {
    console.log('creating new user...');
    // get the data
    var role = document.querySelector('input[name="role"]:checked').value;
    const email = document.getElementById('new-user-email').value;

    // format the role
    // value will be "Domain Admin", "Super Admin", "Badge Admin", "Viewer"
    // format to "domainadmin", "superadmin", "badgeadmin", "viewer"
    if (role == 'Domain Admin') {
        role = 'domainadmin';
    } else if (role == 'Super Admin') {
        role = 'superadmin';
    } else if (role == 'Badge Admin') {
        role = 'badgeadmin';
    } else if (role == 'Viewer') {
        role = 'viewer';
    } else if (role == 'Fire Marshal') {
        role = 'firemarshal';
    } else {
        console.error('Role is invalid');
        showError('', 'Create User', 'Please select a valid role.', null, false, false);
        return false;
    }

    const data = {
        email: email,
        invitedBy: window.userID,
        domain: newUserSelectedDomain,
        role: role,
        status: 'created'
    };

    // check the data
    // email
    if (data.email && data.email.length == 0) {
        console.log('User email is empty');
        showError('', 'Create User', 'Please enter a valid email address.', null, false, false);
        return false;
    } else {
        // check the email is valid
        if (!validateEmail(data.email)) {
            console.log('User email is invalid');
            showError('', 'Create User', 'Please enter a valid email address.', null, false, false);
            return false;
        }
    }

    // if there's no domain and they are not a super admin
    // super admins do not require a domain
    if (data.domain && data.domain.length == 0 && data.role !== 'superadmin') {
        console.log('User has no domain');
        // send sentry for this - domains should be automatically set, so this is a runtime error, not user input error
        showError('', 'Create User', 'There was an error creating the user.', new Error('User has no domain'), false, true);
        return false;
    }

    // role
    if (data.role && data.role.length == 0) {
        console.log('User has no role');
        showError('', 'Create User', 'Please select a valid role.', null, false, false);
        return false;
    }

    // print the user data
    console.log('User data:', data);

    // check if user already exists
    const adminRef = query(collection(db, 'users'), where('email', '==', email));
    const adminQuerySnapshot = await getDocs(adminRef);

    if (adminQuerySnapshot.size > 0) {
        console.error(`Admin ${email} already exists.`);
        showError('', 'User Already Exists', 'A user with that email already exists in Inscribe.', null, false, false);
        return;
    }

    // check invited users
    const invitedRef = query(collection(db, 'users', 'invitedUsers', 'users'), where('email', '==', email));
    const invitedQuerySnapshot = await getDocs(invitedRef);

    if (invitedQuerySnapshot.size > 0) {
        console.error(`Invited user ${email} already exists.`);
        showError('', 'User Already Exists', 'A user with that email already exists in Inscribe.', null, false, false);
        return;
    }


    // create the map under /users/invitedUsers
    // add the user to the database
    const userRef = await addDoc(collection(db, 'users', 'invitedUsers', 'users'), data);
    console.log('User added with ID: ', userRef.id);

    // close the modal
    closeModal(document.getElementById('new-user-modal'));

    // reload the users table
    await loadUsersManager();

    // wait 500ms, for the modal to close
    await delay(500);

    // clear modal content
    document.getElementById('new-user-email').value = '';
    document.getElementById('newUser-domain').innerHTML = 'Select domain...';
    document.getElementById('newUser-roleSummary').innerHTML = 'Select role...';
    document.getElementById('newUser-domainOptions').innerHTML = '';
    // reset the colours
    document.getElementById('newUser-domain').style.color = getComputedStyle(document.documentElement).getPropertyValue('--form-element-placeholder-color');
    document.getElementById('newUser-roleSummary').style.color = getComputedStyle(document.documentElement).getPropertyValue('--form-element-placeholder-color');
    // uncheck all the role radio buttons
    document.getElementById('newUser-roleOption-domainadmin').getElementsByTagName('input')[0].checked = false;
    document.getElementById('newUser-roleOption-superadmin').getElementsByTagName('input')[0].checked = false;
    document.getElementById('newUser-roleOption-badgeadmin').getElementsByTagName('input')[0].checked = false;
    document.getElementById('newUser-roleOption-viewer').getElementsByTagName('input')[0].checked = false;
    document.getElementById('newUser-roleOption-firemarshal').getElementsByTagName('input')[0].checked = false;

    newUserSelectedDomain = null;

}