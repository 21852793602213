export function alphanumericSort(a, b) {
    const regex = /^(\d+)([A-Za-z]*)$/; // Regular expression to separate numbers and letters
    const matchA = a.identifier.match(regex);
    const matchB = b.identifier.match(regex);

    const numA = matchA ? parseInt(matchA[1], 10) : null;
    const numB = matchB ? parseInt(matchB[1], 10) : null;

    // Check if one identifier is purely alphabetic and the other has a numeric part
    if (numA === null && numB !== null) return 1; // a is purely alphabetic, so it should come after b
    if (numB === null && numA !== null) return -1; // b is purely alphabetic, so a should come before b

    // If both or neither have numeric parts, compare the numeric parts if they exist
    if (numA !== null && numB !== null) {
        if (numA < numB) return -1;
        if (numA > numB) return 1;
    }

    // If numeric parts are equal or both are null, compare the alphabetic parts
    if (matchA && matchB) {
        const alphaA = matchA[2] || a.identifier; // Fallback to original identifier if no match
        const alphaB = matchB[2] || b.identifier; // Fallback to original identifier if no match
        if (alphaA < alphaB) return -1;
        if (alphaA > alphaB) return 1;
    }

    // Identical identifiers or no numeric and alphabetic distinction
    return 0;
}