import { delay } from '../shared/delay.js';
import { showError } from '../shared/error.js';
import { db } from '../shared/firebase.js';
import { collection, getDocs, query, Timestamp, addDoc, getDoc, doc, updateDoc } from 'firebase/firestore';

var pendingRestart = false;

export async function loadKiosks(returnData = false) {
    if (pendingRestart) {
        console.log('Kiosk restart in progress, not reloading table');
        return;
    }
    console.log('Loading kiosks...');

    // get all the kiosks at /domains/{domain}/kiosks
    const kiosksRef = query(collection(db, 'domains', window.userDomain, 'kiosks'));
    const kiosksSnapshot = await getDocs(kiosksRef);

    if (returnData) {
        // don't load the table, just return the data
        return kiosksSnapshot.docs.map(doc => ({
            name: doc.data().location || '-',
            serialNumber: doc.data().serialNumber || '-',
            lastSeen: doc.data().lastSeen,
            osVersion: doc.data().osVersion || '-',
            appVersion: doc.data().webAppVersion || '-',
            extensionVersion: doc.data().extensionVersion || '-',
            batteryLevel: doc.data().batteryLevel ? Math.round(doc.data().batteryLevel * 10) / 10 + '%' : '-',
            charging: doc.data().charging ? 'Charging' : (doc.data().charging === false ? 'Not Charging' : '-'),
            id: doc.id,
        }));
    }

    // reset the table
    document.querySelector('#kiosks-table tbody').innerHTML = '';

    // if there are no kiosks
    if (kiosksSnapshot.size == 0) {
        console.log('No kiosks found');
        const row = document.createElement('tr');
        row.innerHTML = `
        <td colspan="9" style="text-align: center;">No kiosks found</td>
        `;
        document.querySelector('#kiosks-table tbody').appendChild(row);
    } else {
        // there are kiosks
        console.log('Kiosks found');

        // Sort the kiosks by name
        kiosksSnapshot.forEach(async (doc) => {
            // create a row for each kiosk
            await addKioskToTable(doc.data(), doc.id);
        });
    }
}

async function addKioskToTable(kioskData, ID) {
    const name = kioskData.location || '-';
    const serialNumber = kioskData.serialNumber || '-';
    var lastSeen = kioskData.lastSeen;
    if (lastSeen) {
        lastSeen = lastSeen.toDate().toLocaleString() || '-';
    } else {
        lastSeen = '-';
    }
    const osVersion = kioskData.osVersion || '-';
    const appVersion = kioskData.webAppVersion || '-';
    const extensionVersion = kioskData.extensionVersion || '-';

    // round to 1 decimal place
    var batteryLevel = kioskData.batteryLevel ? Math.round(kioskData.batteryLevel * 10) / 10 + '%' : '-';
    var charging = kioskData.charging ? 'Charging' : (kioskData.charging === false ? 'Not Charging' : '-');

    var batteryLevelDisplay;
    if (batteryLevel === '-' && charging === '-') {
        batteryLevelDisplay = 'Unknown';
    } else if (batteryLevel === '-') {
        batteryLevelDisplay = charging;
    } else if (charging === '-') {
        batteryLevelDisplay = batteryLevel;
    } else {
        batteryLevelDisplay = `${batteryLevel} - ${charging}`;
    }


    // viewers cannot restart or disable kiosks
    var restartButton = '';
    var disableButton = '';
    if (window.userRole !== 'viewer') {
        restartButton = `<td><a id="restart-kiosk-button-${ID}">Restart</a></td>`;
        // if enabled, disable button
        if (kioskData.enabled) {
            disableButton = `<td><a id="disable-kiosk-button-${ID}" style="color: red;">Disable</a></td>`;
        } else {
            disableButton = `<td><a id="disable-kiosk-button-${ID}" style="color: green;">Enable</a></td>`;
        }
    } else {
        // viewer - hide the columns
        document.getElementById('kiosks-restart-column').style.display = 'none';
        document.getElementById('kiosks-disable-column').style.display = 'none';
    }

    const row = document.createElement('tr');
    row.id = `kiosk-${ID}`;
    // name, serialNumber, lastSeen, osVersion, appVersion, extensionVersion, disableButton, deleteButton
    row.innerHTML = `
    <th scope="row">${name}</th>
    <td>${serialNumber}</td>
    <td>${lastSeen}</td>
    <td>${osVersion}</td>
    <td>${appVersion}</td>
    <td>${extensionVersion}</td>
    <td>${batteryLevelDisplay}</td>
    ${restartButton}
    ${disableButton}
    `;
    row.id = `kiosk-${ID}`;

    document.querySelector('#kiosks-table tbody').appendChild(row);

    // if not a viewer, add event listeners
    if (window.userRole !== 'viewer') {
        // add event listener for restart button
        document.getElementById(`restart-kiosk-button-${ID}`).addEventListener('mousedown', async () => {
            console.log(`restart-kiosk-button-${ID} clicked`);
            document.getElementById(`restart-kiosk-button-${ID}`).setAttribute('aria-busy', 'true');
            await restartKiosk(ID);
            document.getElementById(`restart-kiosk-button-${ID}`).setAttribute('aria-busy', 'false');
        });

        // add event listener for disable button
        document.getElementById(`disable-kiosk-button-${ID}`).addEventListener('mousedown', async () => {
            console.log(`disable-kiosk-button-${ID} clicked`);
            document.getElementById(`disable-kiosk-button-${ID}`).setAttribute('aria-busy', 'true');
            await disableKiosk(ID);
            document.getElementById(`disable-kiosk-button-${ID}`).setAttribute('aria-busy', 'false');
        });
    }
}

async function restartKiosk(ID) {
    console.log(`restarting kiosk ${ID}`);
    pendingRestart = true;

    // create a kiosk request doc at /domains/{domain}/kioskRequests
    const kioskRequest = {
        kioskID: ID,
        action: 'restart',
        status: 'pending',
        createdTime: Timestamp.now(),
        createdBy: window.userID
    };

    // add the kiosk request to the database
    const kioskRequestRef = await addDoc(collection(db, 'domains', window.userDomain, 'kioskRequests'), kioskRequest);
    console.log('Kiosk request added with ID:', kioskRequestRef.id);

    const startTime = Date.now();
    const timeout = 35000; // 35 seconds

    while (Date.now() - startTime < timeout) {
        // show the timeout countdown on the button
        document.getElementById(`restart-kiosk-button-${ID}`).innerHTML = `Restarting... <span id="timeout-countdown">${Math.floor((timeout - (Date.now() - startTime)) / 1000)}s</span>`;
        const kioskRequestDoc = await getDoc(doc(db, 'domains', window.userDomain, 'kioskRequests', kioskRequestRef.id));
        const kioskRequestData = kioskRequestDoc.data();

        if (kioskRequestData.status === 'success') {
            console.log('Kiosk restarted successfully');
            document.getElementById(`restart-kiosk-button-${ID}`).innerHTML = 'Restart Complete';
            setTimeout(() => {
                document.getElementById(`restart-kiosk-button-${ID}`).innerHTML = 'Restart';
            }, 1000);
            return;
        } else if (kioskRequestData.status === 'error') {
            console.error('Kiosk restart failed');
            document.getElementById(`restart-kiosk-button-${ID}`).innerHTML = 'Restart';
            showError(8010, 'Error Restarting Kiosk', 'There was an error restarting the kiosk. The restart request will not be retried.', null, false, true);
            return;
        } else {
            console.warn('Kiosk request status is not "success" or "error": ', kioskRequestData.status);
        }

        // Wait for a short period before checking again
        await delay(1000);
    }

    console.error('Kiosk request status did not change to "success" or "error" within 10 seconds');
    document.getElementById(`restart-kiosk-button-${ID}`).innerHTML = 'Restart';
    // cancel the update
    await updateDoc(doc(db, 'domains', window.userDomain, 'kioskRequests', kioskRequestRef.id), {
        status: 'cancelled'
    });
    pendingRestart = false;

    showError(8000, 'Error Restarting Kiosk', 'There was an error restarting the kiosk. The restart request will not be retried.', null, false, true);
}

async function disableKiosk(ID) {
    console.log(`disabling kiosk ${ID}`);

    const kioskRef = doc(db, 'domains', window.userDomain, 'kiosks', ID);
    const kioskDoc = await getDoc(kioskRef);
    const kioskData = kioskDoc.data();
    const enabledStatus = kioskData.enabled;
    console.log(`disabling kiosk ${ID} - enabled: ${enabledStatus}`);
    
    await updateDoc(kioskRef, {
        enabled: !enabledStatus
    });

    // depending on the status, update the button text and colour
    document.getElementById(`disable-kiosk-button-${ID}`).innerHTML = enabledStatus ? 'Enable' : 'Disable';
    document.getElementById(`disable-kiosk-button-${ID}`).style.color = enabledStatus ? 'red' : 'green';
    // reload
    await loadKiosks();
}