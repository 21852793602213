import { collection, getDocs, query, where, orderBy, doc, getDoc, addDoc, Timestamp, updateDoc, arrayRemove, arrayUnion } from 'firebase/firestore';
import { db } from '../shared/firebase.js';
import { alphanumericSort } from '../admin/utils/sort.js';
import { openModal, closeModal } from '../shared/modal.js';
import { delay } from '../shared/delay.js';
import { humanReadableDate } from './utils/date-format.js';
import { downloadCSV } from './utils/csv.js';
import { createSpreadsheet } from './utils/sheet.js';
import { downloadPDF } from './utils/pdf.js';
import { showError } from '../shared/error.js';
import { loadKiosks } from './kiosks.js';

var loaningItemID = null;
var loanStudentData = [];
var lastLoanSearchQuery = '';
var loanTableIndex = -1;
window.deferItemsAutoReload = false; // used when we're changing data that could cause the reload to fail
window.itemTypeFilter = {
    type: 'Show All'
};

export async function refreshAllItems() {
    if (window.deferItemsAutoReload) {
        console.warn('deferring items reload - flag set');
        return;
    }
    // get all the items
    const itemsQuery = query(collection(db, 'domains', window.userDomain, 'items'), where('deleted', '==', false));
    const itemsQuerySnapshot = await getDocs(itemsQuery);

    // loop through the items
    itemsQuerySnapshot.forEach((docu) => {
        // refresh the item row
        refreshRow(docu.id);
    });

    // then reload the filters
    loadItemFilters();
}

async function refreshRow(itemID) {
    // check the row exists for this student
    if (!document.getElementById(`item-${itemID}`)) {
        return;
    }

    const row = document.getElementById(`item-${itemID}`);

    const itemTypesRef = query(collection(db, 'domains', window.userDomain, 'itemTypes'), where('deleted', '==', false));
    const itemTypesSnapshot = await getDocs(itemTypesRef);
    const itemTypes = itemTypesSnapshot.docs.map(doc => ({
        name: doc.data().name,
        limit: doc.data().limit,
        kiosk: doc.data().kiosk,
        id: doc.id
    }));

    const itemDocRef = doc(db, 'domains', window.userDomain, 'items', itemID);
    const itemDocSnap = await getDoc(itemDocRef);
    const itemData = itemDocSnap.data();
    let item = {
        ref: itemDocSnap.ref,
        id: itemDocSnap.id,
        ...itemData
    };

    // parse data
    const [identifier, typeName, availability, loanedTo, lastLoaned, status, loaned, , disableButton, disabled] = await parseItemData(item, itemTypes);

    let deleteButton = '';
    if (window.userRole !== 'viewer') {
        deleteButton = `<td><a id="delete-item-${itemID}" style="color: red;">Delete</a></td>`;
    }

    // update the item row
    row.innerHTML = `
    <th scope="row">${identifier}</th>
    <td>${typeName}</td>
    <td>${availability}</td>
    <td>${loanedTo}</td>
    <td>${lastLoaned}</td>
    <td>${status}</td>
    <td><a href="#" id="history-item-${itemID}">View History</a></td>
    ${disableButton}
    ${deleteButton}
    `;

    if (loaned === false) {
        // green, is loaned
        row.style.backgroundColor = 'rgba(124, 179, 66, 0.38)';
    } else {
        // red, is not loaned
        row.style.backgroundColor = 'rgba(216, 27, 96, 0.38)';
    }

    // if disabled, change the colour
    if (disabled) {
        row.style.backgroundColor = 'rgba(255, 0, 0, 0.38)';
    }

    createItemRowListeners(loaned, disabled, itemID);
}

export async function loadItems() {
    console.log('loading items...');

    // if the user is a viewer, hide the disable and delete columns
    if (window.userRole === 'viewer') {
        if (document.getElementById('items-disable-column')) {
            document.getElementById('items-disable-column').remove();
        }
        if (document.getElementById('items-delete-column')) {
            document.getElementById('items-delete-column').remove();
        }

        if (document.getElementById('new-item-type-button')) {
            document.getElementById('new-item-type-button').remove();
        }
        if (document.getElementById('new-item-button')) {
            document.getElementById('new-item-button').remove();
        }
    }

    const itemsRef = query(collection(db, 'domains', window.userDomain, 'items'), where('deleted', '==', false), orderBy('identifier'));
    const itemsSnapshot = await getDocs(itemsRef);

    // get all the item types
    const itemTypesRef = query(collection(db, 'domains', window.userDomain, 'itemTypes'), where('deleted', '==', false));
    const itemTypesSnapshot = await getDocs(itemTypesRef);
    const itemTypes = itemTypesSnapshot.docs.map(doc => ({
        name: doc.data().name,
        limit: doc.data().limit,
        kiosk: doc.data().kiosk,
        id: doc.id
    }));

    // Clear the table first
    document.querySelector('#items-table tbody').innerHTML = '';

    // Convert snapshot to array of item objects
    let items = itemsSnapshot.docs.map(doc => ({
        ref: doc.ref, // Store the document reference
        id: doc.id,
        ...doc.data()
    }));

    // Sort items by identifier using the custom alphanumeric sort function
    items.sort(alphanumericSort);

    // get all the items ready to put into the table
    const rowsPromises = items.map(async (data) => {
        return await parseItemData(data, itemTypes);
    });

    // Wait for all promises to resolve
    console.log('waiting for all rows to resolve');
    const rows = await Promise.all(rowsPromises);
    console.log('all rows resolved');

    // add the rows to the table
    rows.forEach(row => {
        const itemID = row[7];
        const identifier = row[0];
        const typeName = row[1];
        const availability = row[2];
        const loanedTo = row[3];
        const lastLoaned = row[4];
        const status = row[5];
        const loaned = row[6];
        const disableButton = row[8];
        const disabled = row[9];

        let deleteButton = '';
        if (window.userRole !== 'viewer') {
            deleteButton = `<td><a id="delete-item-${itemID}" style="color: red;">Delete</a></td>`;
        }

        const rowElement = document.createElement('tr'); // Create a new TR element

        const rowHTML = `
    <th scope="row">${identifier}</th>
    <td>${typeName}</td>
    <td>${availability}</td>
    <td>${loanedTo}</td>
    <td>${lastLoaned}</td>
    <td>${status}</td>
    <td><a href="#" id="history-item-${itemID}">View History</a></td>
    ${disableButton}
    ${deleteButton}
    `;

        rowElement.innerHTML = rowHTML; // Set the inner HTML of the created TR element
        rowElement.id = `item-${itemID}`; // Set the ID of the TR element

        // if loaned, change the colour
        if (loaned === false) {
            // green, is loaned
            rowElement.style.backgroundColor = 'rgba(124, 179, 66, 0.38)';
        } else {
            // red, is not loaned
            rowElement.style.backgroundColor = 'rgba(216, 27, 96, 0.38)';
        }

        // if disabled, change the colour
        if (row[9] === true) {
            rowElement.style.backgroundColor = 'rgba(255, 0, 0, 0.38)';
        }

        document.querySelector('#items-table tbody').appendChild(rowElement); // Append the TR element to the table
        createItemRowListeners(loaned, disabled, itemID);
    });

    // all done, load the filters
    await loadItemFilters();
}

async function parseItemData(data, itemTypes) {
    const itemID = data.id;
    const itemTypeData = itemTypes.find(itemType => itemType.id === data.type) || {};
    const typeName = itemTypeData.name || 'Unknown';

    // check if the item is loaned
    var availability = 'Unknown'; // text to display in the table
    var loaned = data.loaned || false; // bool

    if (loaned === true) {
        availability = 'No';
    } else if (loaned === false) {
        availability = 'Yes';
    }

    // parse the last loaned and last returned
    var lastLoaned = '';
    var loanedTo = '';
    var mostRecentRecord = null;
    var disableButton = '';

    if (data.lastLoaned) {
        // query the most recent itemHistory record for this item, by the item ID (the firestore document ID)
        const itemHistoryRef = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'itemHistory'), where('archived', '==', false));
        const itemHistoryQuery = await getDocs(itemHistoryRef);

        itemHistoryQuery.forEach((docu) => {
            const historyData = docu.data();
            if (historyData.item === itemID) {
                // found this item
                if (mostRecentRecord === null || historyData.timestamp.toDate() > mostRecentRecord.timestamp.toDate()) {
                    mostRecentRecord = historyData;
                }
            }
        });

        if (mostRecentRecord) {
            // check if there is a student - otherwise it's an admin manual loan with a note
            if (mostRecentRecord.student) {
                // get the student name
                const studentRef = doc(db, 'domains', window.userDomain, 'students', mostRecentRecord.student);
                const studentSnapshot = await getDoc(studentRef);
                const studentData = studentSnapshot.data();

                loanedTo = `${studentData.studentName} (${studentData.formGroup})`;
            } else {
                // no student - admin loan
                // get the admin ID
                const adminRef = doc(db, 'users', mostRecentRecord.admin);
                const adminSnapshot = await getDoc(adminRef);
                const adminData = adminSnapshot.data();

                loanedTo = mostRecentRecord.note || '';

                // append the admin name
                loanedTo += ` (Admin: ${adminData.name})`;
            }

        }

        lastLoaned = humanReadableDate(data.lastLoaned.toDate(), 'simple');
    }

    // capitalise the status
    const status = data.status.charAt(0).toUpperCase() + data.status.slice(1);

    var disabled = false;
    if (status === 'Disabled') {
        disabled = true;
        if (window.userRole !== 'viewer') {
            disableButton = `<td><a href="#" id="disable-item-${itemID}" style="color: green;">Enable</a></td>`;
        }
    } else {
        // item is not disabled
        if (window.userRole !== 'viewer') {
            disableButton = `<td><a href="#" id="disable-item-${itemID}" style="color: red;">Disable</a></td>`;
        }
    }

    // item is not loaned
    if (!loaned) {
        loanedTo = '-';
        lastLoaned = '-';
    }

    // if the user is not a viewer, check what loan/return button to show
    if (window.userRole !== 'viewer') {
        // if the item is disabled, never show the loan/return button
        if (status === 'Disabled') {
            availability = '-'; // items are always returned before being disabled
        } else if (!loaned) {
            availability = `Yes - <a href="#" id="loan-item-${itemID}">Loan</a>`;
        } else {
            availability = `No - <a href="#" id="return-item-${itemID}">Return</a>`;
        }
    }

    return [data.identifier, typeName, availability, loanedTo, lastLoaned, status, loaned, itemID, disableButton, disabled];

}

async function createItemRowListeners(loaned, disabled, itemID) {
    // only show the loan/return button for non-viewers and enabled items
    if (window.userRole !== 'viewer' && !disabled) {
        if (loaned === false) {
            // Add event listener to loan the item
            document.getElementById(`loan-item-${itemID}`).addEventListener('mousedown', async () => {
                document.getElementById(`loan-item-${itemID}`).setAttribute('aria-busy', 'true');
                await prepareLoanStudentSearch();
                loaningItemID = itemID;
                openModal(document.getElementById('loan-item'));
                // wait 200ms
                await delay(200);
                // focus the search box
                document.getElementById('loan-item-search').focus();
                document.getElementById(`loan-item-${itemID}`).setAttribute('aria-busy', 'false');
            });
        } else {
            // Add event listener to return the item
            document.getElementById(`return-item-${itemID}`).addEventListener('mousedown', async () => {
                document.getElementById(`return-item-${itemID}`).setAttribute('aria-busy', 'true');
                // return the item
                await returnItem(itemID);
                await delay(200);
            });
        }

        // Add event listener to delete the item
        document.getElementById(`delete-item-${itemID}`).addEventListener('mousedown', async () => {
            // set the global variable
            window.deletingObjectID = itemID;
            window.deletingObjectType = 'item';
            // show the delete confirm modal
            openModal(document.getElementById('confirm-delete-modal'));
        });
    }

    // only show disable/enable button for non-viewers
    if (window.userRole !== 'viewer') {
        // Add event listener to disable the item
        document.getElementById(`disable-item-${itemID}`).addEventListener('mousedown', async () => {
            document.getElementById(`disable-item-${itemID}`).setAttribute('aria-busy', 'true');
            await disableItem(itemID);
            await delay(200);
            document.getElementById(`disable-item-${itemID}`).setAttribute('aria-busy', 'false');
        });
    }

    // Add event listener to view the item history
    document.getElementById(`history-item-${itemID}`).addEventListener('mousedown', async () => {
        document.getElementById(`history-item-${itemID}`).setAttribute('aria-busy', 'true');
        await fillItemHistoryModal(itemID);
        // set the attribute of export-item-history-button to the id
        document.getElementById('export-item-history-button').setAttribute('data-item-id', itemID);
        openModal(document.getElementById('item-history-modal'));
        document.getElementById(`history-item-${itemID}`).setAttribute('aria-busy', 'false');
    });
}

async function disableItem(itemID) {
    console.log(`disabling item ${itemID}`);

    // get the item document
    const itemRef = doc(db, 'domains', window.userDomain, 'items', itemID);
    const itemDoc = await getDoc(itemRef);

    // if the item is not found, error
    if (!itemDoc.exists()) {
        console.error('Item not found');
        return;
    }

    // if loaned, return
    if (itemDoc.data().loaned) {
        console.warn('Item is loaned');
        await returnItem(itemID);
    }

    // update the item document to the opposite of the current status
    // disabled, active
    await updateDoc(itemRef, {
        status: itemDoc.data().status === 'disabled' ? 'active' : 'disabled'
    });

    // create a history entry
    await addDoc(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'itemHistory'), {
        item: itemID,
        action: itemDoc.data().status === 'disabled' ? 'enabled' : 'disabled',
        student: null,
        timestamp: Timestamp.now(),
        admin: window.userID,
        archived: false
    });

    // reload this item row
    await refreshRow(itemID);
    // apply the filters
    await applyItemFilters();
}

async function fillItemHistoryModal(itemID) {
    console.log(`filling item history modal for item ${itemID}`);
    // clear the table
    document.querySelector('#item-history-table tbody').innerHTML = '';

    // get the item history
    const itemHistoryRef = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'itemHistory'), where('item', '==', itemID), where('archived', '==', false), orderBy('timestamp', 'desc'));
    const itemHistorySnapshot = await getDocs(itemHistoryRef);

    // if no history found, show no history found
    if (itemHistorySnapshot.size === 0) {
        console.log('no history found');
        document.querySelector('#item-history-table tbody').innerHTML = '<tr><td colspan="4" style="text-align: center;">No history found</td></tr>';
        return;
    }

    // Collect history data with promises to handle asynchronous fetching of student names
    const itemHistoryDataPromises = itemHistorySnapshot.docs.map(async (historyDoc) => {
        const historyData = historyDoc.data();

        let studentName = '-';
        let studentFormGroup = '-';

        // if there is no student and the action is loaned or returned, get the admin name
        if (!historyData.student && (historyData.action === 'loaned' || historyData.action === 'returned')) {
            const adminRef = doc(db, 'users', historyData.admin);
            const adminDoc = await getDoc(adminRef);
            const adminData = adminDoc.data();
            studentName = `${adminData.name} (Admin)`;
            // note
            studentFormGroup = historyData.note || '-';
        }

        // if the action is loaned or returned , get the student name
        else if (historyData.action === 'loaned' || historyData.action === 'returned') {
            const studentRef = doc(db, 'domains', window.userDomain, 'students', historyData.student);
            const studentDoc = await getDoc(studentRef);
            const studentData = studentDoc.data();
            studentName = studentData.studentName;
            studentFormGroup = studentData.formGroup;
        }

        // if the action is disabled or enabled, get the admin name
        else if (historyData.action === 'disabled' || historyData.action === 'enabled' || historyData.action === 'created' || historyData.action === 'deleted') {
            const adminRef = doc(db, 'users', historyData.admin);
            const adminDoc = await getDoc(adminRef);
            const adminData = adminDoc.data();
            studentName = `${adminData.name} (Admin)`;
            studentFormGroup = '-';
        }

        return {
            studentName,
            studentFormGroup,
            action: historyData.action.charAt(0).toUpperCase() + historyData.action.slice(1),
            timestamp: humanReadableDate(historyData.timestamp.toDate(), 'simple'),
            // filter time as YYYY-MM-DD only 
            filterTime: historyData.timestamp.toDate().toISOString().split('T')[0]
        };
    });

    // Wait for all history data to be fetched and processed
    const itemHistoryData = await Promise.all(itemHistoryDataPromises);

    // Now append each row to the table in the correct order
    const tableBody = document.querySelector('#item-history-table tbody');
    itemHistoryData.forEach(data => {
        const rowElement = document.createElement('tr');
        rowElement.innerHTML = `
        <td>${data.studentName}</td>
        <td>${data.studentFormGroup}</td>
        <td>${data.action}</td>
        <td data-filter-time="${data.filterTime}">${data.timestamp}</td>
        `;
        tableBody.appendChild(rowElement);
    });

    resetFilters();
}


// load the data into a local variable
// for use in the search function
async function prepareLoanStudentSearch() {
    console.log('preparing loan student search');

    loanStudentData = [];
    // clear the table & search query
    document.querySelector('#loan-item-table tbody').innerHTML = '';
    document.getElementById('loan-item-search').value = '';
    lastLoanSearchQuery = '';

    // get the students
    const studentsQuery = query(collection(db, 'domains', window.userDomain, 'students'), where('archived', '==', false), where('intervals', 'array-contains', window.currentInterval));
    const studentsQuerySnapshot = await getDocs(studentsQuery);

    // if no students found, show no students found
    if (studentsQuerySnapshot.size === 0) {
        console.log('no students found');
        document.querySelector('#loan-item-table tbody').innerHTML = '<tr><td colspan="2" style="text-align: center;">No students found</td></tr>';
        return;
    }

    console.log(`Found ${studentsQuerySnapshot.size} students`);

    // loop through students
    studentsQuerySnapshot.forEach((studentDoc) => {
        // get the student document
        const studentDocData = studentDoc.data();

        // add the student to the search data
        loanStudentData.push({
            studentName: studentDocData.studentName,
            formGroup: studentDocData.formGroup || 'Unknown',
            misID: studentDoc.id,
            loanedItems: studentDocData.loanedItems,
            loanPermission: studentDocData.loanPermission,
            lastInitial: studentDocData.lastInitial
        });
    });

    console.log('loan student search data prepared');
    loanTableIndex = -1;

    await delay(200);
    // focus the search box
    document.getElementById('loan-item-search').focus();
}

// Return an item
export async function returnItem(itemID) {
    console.log(`Returning item ${itemID}`);

    // use this as a static time across all DB calls
    const now = Timestamp.now();

    // get the item data
    const itemDocRef = doc(db, 'domains', window.userDomain, 'items', itemID);
    const itemDocSnap = await getDoc(itemDocRef);
    const itemData = itemDocSnap.data();

    const studentID = itemData.loanedTo || null;

    // if the item is not found, error
    if (itemData === null) {
        console.error('Item not found');
        return;
    }

    // if the item is not loaned, error
    if (!itemData.loaned) {
        console.error('Item is not loaned');
        return;
    }

    // update the item document
    await updateDoc(itemDocRef, {
        loaned: false,
        loanedTo: null,
        lastReturned: now,
        lastLoaned: null
    });

    // if we don't have a student ID, we're not returning to a student, so nothing to do here
    if (studentID) {
        // remove the item from the student's loanedItems array
        await updateDoc(doc(db, 'domains', window.userDomain, 'students', studentID), {
            loanedItems: arrayRemove(itemID)
        });
    }

    // add a item history entry
    await addDoc(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'itemHistory'), {
        item: itemID,
        action: 'returned',
        student: studentID,
        timestamp: now,
        archived: false,
        admin: window.userID,
        kiosk: null,
        type: 'admin'
    });



    await refreshRow(itemID);
    // apply the filters
    await applyItemFilters();

}


// Process a loan - takes the student ID as a parameter
async function processLoan(studentID) {
    const itemID = loaningItemID;
    console.log(`Processing loaning of item ${itemID} to student ${studentID}`);

    // use this as a static time across all DB calls
    const now = Timestamp.now();

    // load the item data
    const itemDocRef = doc(db, 'domains', window.userDomain, 'items', itemID);
    const itemDocSnap = await getDoc(itemDocRef);
    const itemData = itemDocSnap.data();

    // if the item is not found, error
    if (itemData === null) {
        console.error('Item not found');
        await showError(`7001-${studentID}-${itemID}`, 'There was an error.', 'This item could not be found. Please try again.', null, false, true);
        return;
    }

    // if the item is loaned, error
    if (itemData.loaned) {
        console.error('Item is loaned');
        await showError(`7002-${studentID}-${itemID}`, 'This item is already loaned.', 'This item is already loaned. Please return it and try again.', null, false, true);
        return;
    }

    // update the item document
    await updateDoc(itemDocRef, {
        loaned: true,
        loanedTo: studentID,
        lastLoaned: now,
        lastReturned: null
    });

    // add the item to the student's loanedItems array
    await updateDoc(doc(db, 'domains', window.userDomain, 'students', studentID), {
        loanedItems: arrayUnion(itemID)
    });

    // add a item history entry
    await addDoc(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'itemHistory'), {
        item: itemID,
        action: 'loaned',
        student: studentID,
        timestamp: now,
        archived: false,
        admin: window.userID,
        kiosk: null,
        type: 'admin'
    });

    await refreshRow(itemID);
    // apply the filters
    await applyItemFilters();

    loaningItemID = null;
    loanStudentData = [];
}



export async function applyItemFilters() {
    const targetType = window.itemTypeFilter.type;

    console.log(`applying item filters for type: ${targetType}`);

    var tableRows = document.querySelectorAll('#items-table tbody tr');

    // hide the 'no records found' row if it exists
    if (document.getElementById('items-no-records')) {
        document.getElementById('items-no-records').remove();
    }

    // if the type is show all, show all rows
    if (targetType === 'Show All') {
        tableRows.forEach(function (row) {
            row.style.display = '';
        });
        return;
    }

    tableRows.forEach(function (row) {
        var typeCell = row.getElementsByTagName('td')[0];
        var type = typeCell ? typeCell.textContent || typeCell.innerText : '';

        if (type === targetType || window.itemTypeFilter === 'Show All') {
            row.style.display = '';
        } else {
            row.style.display = 'none';
        }
    });

    // if no rows are visible, show a message
    const table = document.querySelector('#items-table tbody');
    const rows = table.querySelectorAll('tr');

    let hiddenRowsCount = 0;
    rows.forEach(row => {
        if (window.getComputedStyle(row).display === 'none') {
            hiddenRowsCount++;
        }
    });

    // if all rows are hidden, show a message
    if (rows.length === hiddenRowsCount) {
        // add a row to the table
        console.log('no records found');
        const row = document.createElement('tr');
        row.innerHTML = `
        <th scope="row" colspan="7" style="text-align: center;" id="items-no-records">No items found</th>
        `;
        document.querySelector('#items-table tbody').appendChild(row);
    }
}

// contents of the loan search box have changed
export async function loanSearchNameChanged() {
    // if the search query is empty, clear the table
    if (document.getElementById('loan-item-search').value === '') {
        document.querySelector('#loan-item-table tbody').innerHTML = '';
        return;
    }

    const tableBody = document.querySelector('#loan-item-table tbody');
    var matchedStudents = [];

    // check if the query has changed
    if (document.getElementById('loan-item-search').value !== lastLoanSearchQuery) {
        loanTableIndex = -1; // reset as the number of rows may have changed
        lastLoanSearchQuery = document.getElementById('loan-item-search').value;
        tableBody.innerHTML = '';
        loanStudentData.forEach((student) => {
            // if the student name contains the search query
            if (student.studentName.toLowerCase().includes(lastLoanSearchQuery.toLowerCase())) {
                matchedStudents.push(student);
                // create the row
                const row = document.createElement('tr');
                row.innerHTML = `
                <td>${student.studentName}</td>
                <td>${student.formGroup}</td>
                `;
                row.id = `loan-student-${student.misID}`;


                // if the student has no loaned items, and they have loan permission
                if (student.loanPermission) {
                    // add the row to the table
                    tableBody.appendChild(row);

                    document.getElementById(`loan-student-${student.misID}`).addEventListener('mousedown', async () => {
                        console.log(`row for student ${student.misID} has been clicked`);
                        await studentClickedLoanTable(student.misID);
                    });
                } else {
                    //// TODO: IMPLEMENT LOAN TYPE LIMITS 
                    row.style.textDecoration = 'line-through';
                    // hover text
                    if (!student.loanPermission) {
                        row.title = 'This student does not have loan permission';
                    }
                    // add the row to the table
                    tableBody.appendChild(row);
                }
            }
        });

        // if there's only one student, automatically focus on it
        if (matchedStudents.length === 1) {
            const row = document.querySelector('#loan-student-' + matchedStudents[0].misID);
            row.style.backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-focus');
            row.scrollIntoView({ block: 'nearest', inline: 'start' });
            loanTableIndex = 0;
        }

    } else {
        console.log('search query has not changed');
        return;
    }
}

// when a student is clicked in the loan table
// physical click or enter key
async function studentClickedLoanTable(studentID) {
    document.getElementById('loan-progress').style.display = 'block';
    // update the selected domain
    await processLoan(studentID);
    // close the modal
    closeModal(document.getElementById('loan-item'));

    // reset the search query & table
    document.getElementById('loan-item-search').value = '';
    document.querySelector('#loan-item-table tbody').innerHTML = '';
    lastLoanSearchQuery = '';
    loanStudentData = [];
    loanTableIndex = 0;
    document.getElementById('loan-progress').style.display = 'none';
}

// enter key in the loan modal
export async function selectCurrentItemLoanStudent(event) {
    const rows = document.querySelectorAll('#loan-item-table tbody tr');

    if (event.key === 'Enter') {
        event.preventDefault();
        if (loanTableIndex >= -1) {
            await selectCurrentResult();
        }
    } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        if (loanTableIndex < rows.length - 1) {
            loanTableIndex++;
            updateRowSelection(rows);
        }
    } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        if (loanTableIndex > 0) {
            loanTableIndex--;
            updateRowSelection(rows);
        }
    }
}

async function selectCurrentResult() {
    const rows = document.querySelectorAll('#loan-item-table tbody tr');
    if (loanTableIndex >= 0 && loanTableIndex < rows.length) {
        // Defocus the search box
        document.getElementById('loan-item-search').blur();
        const studentID = rows[loanTableIndex].id.replace('loan-student-', '');
        console.log(`row for student ${studentID} has been clicked`);
        await studentClickedLoanTable(studentID);
    }
}

function updateRowSelection(rows) {
    // Reset the background color for all rows
    rows.forEach(row => {
        row.style.backgroundColor = ''; // Reset to default
    });

    // Set the background color for the currently selected row
    if (loanTableIndex >= 0 && loanTableIndex < rows.length) {
        const selectedRow = rows[loanTableIndex];
        selectedRow.style.backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-focus');

        // Ensure the selected row is visible, especially useful for long tables
        selectedRow.scrollIntoView({ block: 'nearest', inline: 'start' });
    }
}


async function resetFilters() {
    console.log('resetting filters');
    window.itemHistoryFilters = {
        name: '',
        formGroup: 'Show All',
        date: null, // show all by default
        action: 'Show All',
    };

    // Clear the search input field
    document.getElementById('item-history-search').value = '';

    // Reset the date input field
    document.getElementById('item-dateInput').value = '';

    // Reset the form group filter - show-all-item-history-form-group-dropdown
    document.getElementById('show-all-item-history-form-group-dropdown').checked = true;
    document.getElementById('item-history-formFilter').textContent = 'Show All';

    // Reset the action filter - item-history-show-all-action
    document.getElementById('item-history-show-all-action').checked = true;
    document.getElementById('item-history-actionFilter').textContent = 'Show All';
}


export async function applyItemHistoryFilters() {
    console.log('applying item history filters');

    // Preprocess filters for efficient comparisons
    const nameFilter = window.itemHistoryFilters.name.toLowerCase();
    const actionFilter = window.itemHistoryFilters.action;
    const formGroupFilter = window.itemHistoryFilters.formGroup;
    const dateFilter = window.itemHistoryFilters.date; // this will be YYYY-MM-DD

    const tableRows = Array.from(document.querySelectorAll('#item-history-table tbody tr'));
    // remove the no-records-row from the filter - so we don't remove this by it not matching the filters
    const noRecordsRow = document.getElementById('item-history-no-records');
    if (noRecordsRow) {
        tableRows.splice(tableRows.indexOf(noRecordsRow), 1);
    }

    let visibleRowsCount = 0;

    tableRows.forEach(function (row) {
        const nameCell = row.getElementsByTagName('td')[0];
        const formGroupCell = row.getElementsByTagName('td')[1];
        const actionCell = row.getElementsByTagName('td')[2];
        const dateCell = row.getElementsByTagName('td')[3];

        const name = nameCell ? nameCell.textContent.toLowerCase() : '';
        const formGroup = formGroupCell ? formGroupCell.textContent : '';
        const action = actionCell ? actionCell.textContent : '';
        const date = dateCell.getAttribute('data-filter-time') || '';


        const nameMatch = name.includes(nameFilter);
        const formGroupMatch = (formGroupFilter === 'Show All' || formGroup === formGroupFilter);
        const actionMatch = (actionFilter === 'Show All' || action.toString().includes(actionFilter));
        const dateMatch = (!dateFilter || date === dateFilter);

        if (nameMatch && formGroupMatch && actionMatch && dateMatch) {
            row.style.display = '';
            visibleRowsCount++;
        } else {
            row.style.display = 'none';
        }
    });

    // Handle the case where no records match the filters
    if (visibleRowsCount === 0) {
        if (!noRecordsRow) {
            const row = document.createElement('tr');
            row.id = 'item-history-no-records';
            row.innerHTML = '<td colspan="4" style="text-align: center;">No records found</td>';
            document.querySelector('#item-history-table tbody').appendChild(row);
        }
    } else {
        if (noRecordsRow) {
            noRecordsRow.remove();
        }
    }

    console.log(`rows processed, ${visibleRowsCount} visible`);
}

export async function exportItemHistory(itemID, format) {
    console.log('exporting item history for item', itemID);

    var exportData = [];

    // Pre-fetch all student data and store in an object
    const students = {};

    // get all records - for the date
    const studentsRef = query(collection(db, 'domains', window.userDomain, 'students'), where('archived', '==', false), where('intervals', 'array-contains', window.currentInterval));

    await getDocs(studentsRef).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const studentData = doc.data();
            students[studentData.misID] = studentData;
        });
    });

    // get item history
    const itemHistoryRef = query(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'itemHistory'), where('item', '==', itemID), where('archived', '==', false), orderBy('timestamp', 'desc'));
    const itemHistorySnapshot = await getDocs(itemHistoryRef);

    // get this item
    const itemRef = doc(db, 'domains', window.userDomain, 'items', itemID);
    const itemDoc = await getDoc(itemRef);
    const itemData = itemDoc.data();

    // get the type of this item
    const itemTypeRef = doc(db, 'domains', window.userDomain, 'itemTypes', itemData.type);
    const itemTypeSnapshot = await getDoc(itemTypeRef);
    const itemTypeData = itemTypeSnapshot.data();
    const typeName = itemTypeData.name || 'Unknown';

    // for each history record
    for (const historyDoc of itemHistorySnapshot.docs) {
        const historyData = historyDoc.data();

        let studentName = '-';
        let formGroup = '-';

        // if there is no student and the action is loaned or returned, get the admin name
        if (!historyData.student && (historyData.action === 'loaned' || historyData.action === 'returned')) {
            const adminRef = doc(db, 'users', historyData.admin);
            const adminDoc = await getDoc(adminRef);
            const adminData = adminDoc.data();
            studentName = `${adminData.name} (Admin)`;
            // note
            formGroup = historyData.note || '-';
        }

        // if the action is loaned or returned , get the student name
        else if (historyData.action === 'loaned' || historyData.action === 'returned') {
            const studentRef = doc(db, 'domains', window.userDomain, 'students', historyData.student);
            const studentDoc = await getDoc(studentRef);
            const studentData = studentDoc.data();
            studentName = studentData.studentName;
            formGroup = studentData.formGroup;
        }

        // if the action is disabled or enabled, get the admin name
        else if (historyData.action === 'disabled' || historyData.action === 'enabled' || historyData.action === 'created' || historyData.action === 'deleted') {
            const adminRef = doc(db, 'users', historyData.admin);
            const adminDoc = await getDoc(adminRef);
            const adminData = adminDoc.data();
            studentName = `${adminData.name} (Admin)`;
            formGroup = '-';
        }

        else {
            console.error('Unknown action:', historyData.action);
            // go to next record
            continue;
        }

        // get the time
        const time = historyData.timestamp.toDate().toLocaleString();

        // get the action
        var action = '';
        if (historyData.action === 'returned') {
            action = 'Returned';
        } else if (historyData.action === 'loaned') {
            action = 'Loaned';
        } else if (historyData.action === 'disabled') {
            action = 'Disabled';
        } else if (historyData.action === 'enabled') {
            action = 'Enabled';
        } else if (historyData.action === 'created') {
            action = 'Created';
        } else if (historyData.action === 'deleted') {
            action = 'Deleted';
        } else {
            action = 'Unknown';
        }

        const data = {
            studentName,
            formGroup,
            action,
            time: time,
            // use YYYY-MM-DD only for filtering
            timeFilter: historyData.timestamp.toDate().toISOString().split('T')[0]
        };

        // check if it matches filters
        if (!doesItemHistoryRecordMatch(data)) {
            continue; // go to next record
        }

        if (format !== 'sheet') {
            exportData.push({
                Name: studentName,
                FormGroup: formGroup,
                Date: time,
                Action: action
            });
        } else {
            exportData.push([
                studentName,
                formGroup,
                time,
                action
            ]);
        }
    }

    console.log(`exporting ${exportData.length} records`);

    if (format === 'pdf') {
        downloadPDF('Item History Report', `${typeName} ${itemData.identifier}`, ['Name', 'FormGroup', 'Date', 'Action'], exportData, `item-history-report-${typeName}-${itemData.identifier}`.toLowerCase());
    } else if (format === 'csv') {
        downloadCSV(exportData, ['Name', 'FormGroup', 'Date', 'Action'], `item-history-report-${typeName}-${itemData.identifier}`.toLowerCase());
    } else if (format === 'sheet') {
        const spreadsheet = await createSpreadsheet(`Item History Report - ${typeName} ${itemData.identifier}`, ['Name', 'Form Group', 'Date', 'Action'], exportData);
        if (spreadsheet) {
            console.log('Spreadsheet created - ID:', spreadsheet.spreadsheetId);
            // open the URL - spreadsheetUrl
            window.open(spreadsheet.spreadsheetUrl, '_blank');
        }
        // if there was an error, the function handles it
    }
}

export function doesItemHistoryRecordMatch(record) {
    // Preprocess filters for efficient comparisons
    const nameFilter = window.itemHistoryFilters.name.toLowerCase();
    const actionFilter = window.itemHistoryFilters.action;
    const formGroupFilter = window.itemHistoryFilters.formGroup;
    const dateFilter = window.itemHistoryFilters.date; // this will be YYYY-MM-DD

    // Extract properties from the record
    const name = record.studentName ? record.studentName.toLowerCase() : '';
    const formGroup = record.formGroup || '';
    const action = record.action || '';
    const date = record.timeFilter || '';

    // Apply the same matching logic
    const nameMatch = name.includes(nameFilter);
    const formGroupMatch = (formGroupFilter === 'Show All' || formGroup === formGroupFilter);
    const actionMatch = (actionFilter === 'Show All' || action.toString().includes(actionFilter));
    const dateMatch = (!dateFilter || date === dateFilter);

    // Return true if all filters match, otherwise false
    return nameMatch && formGroupMatch && actionMatch && dateMatch;
}

export async function validateCSVImport() {
    console.log('validating CSV import');
    window.deferItemsAutoReload = true;

    const file = document.getElementById('items-csv-import').files[0] || null;
    const errorDisplay = document.getElementById('items-csv-import-error');
    errorDisplay.innerHTML = '';

    if (file) {
        errorDisplay.innerHTML = 'Validating CSV file...';
        errorDisplay.setAttribute('aria-busy', 'true');
        document.getElementById('create-new-item-btn').setAttribute('disabled', 'true');

        const importTypes = {};

        // Get all types, including deleted ones (user may be re-creating)
        const itemTypesRef = query(collection(db, 'domains', window.userDomain, 'itemTypes'), where('deleted', '==', false));
        const itemTypesSnapshot = await getDocs(itemTypesRef);

        // Store type names and ids for comparison
        itemTypesSnapshot.forEach(doc => {
            const typeData = doc.data();
            importTypes[typeData.name] = {
                id: doc.id,
                items: new Set()
            };
        });

        // Get all non-deleted items, including deleted ones (user may be re-creating)
        const itemsRef = query(collection(db, 'domains', window.userDomain, 'items'), where('deleted', '==', false));
        const itemsSnapshot = await getDocs(itemsRef);

        // Store item identifiers by type name
        itemsSnapshot.forEach(doc => {
            const item = doc.data();
            const typeName = Object.keys(importTypes).find(name => importTypes[name].id === item.type);
            if (typeName) {
                importTypes[typeName].items.add(item.identifier);
            }
        });

        const existingTypeNames = Object.keys(importTypes);

        const reader = new FileReader();
        reader.onload = async function (event) {
            const csvData = event.target.result.trim();
            const rows = csvData.split('\n').map(row => row.split(','));

            const normalizeHeader = header => header.replace(/\s+/g, '').toLowerCase();
            const headers = rows[0].map(header => normalizeHeader(header));

            const requiredHeaders = ['identifier', 'typename']; // serial number is optional
            const normalizedRequiredHeaders = requiredHeaders.map(header => normalizeHeader(header));
            const missingHeaders = normalizedRequiredHeaders.filter(header => !headers.includes(header));

            if (missingHeaders.length > 0) {
                missingHeaders.forEach(header => {
                    const originalHeader = requiredHeaders.find(reqHeader => normalizeHeader(reqHeader) === header);
                    errorDisplay.innerHTML = `<p style="margin: auto; color: red;">Missing header: ${originalHeader}</p>`;
                });
                errorDisplay.setAttribute('aria-busy', 'false');
                return;
            }

            let newTypes = new Set();
            let newItems = [];

            errorDisplay.innerHTML = '';

            for (let i = 1; i < rows.length; i++) {
                const row = rows[i].map(cell => cell.trim());
                const identifierIndex = headers.indexOf(normalizeHeader('identifier'));
                const typeNameIndex = headers.indexOf(normalizeHeader('typename'));
                const serialNumberIndex = headers.indexOf(normalizeHeader('serialnumber'));

                const identifier = row[identifierIndex];
                const typeName = row[typeNameIndex];
                const serialNumber = row[serialNumberIndex];
                let typeID = null;

                if (!typeName) {
                    errorDisplay.innerHTML += `<p style="margin: auto; color: red;">Row ${i + 1}: Missing type name</p>`;
                    continue;
                }

                if (!identifier) {
                    errorDisplay.innerHTML += `<p style="margin: auto; color: red;">Row ${i + 1}: Missing identifier</p>`;
                    continue;
                }

                if (!existingTypeNames.includes(typeName) && !newTypes.has(typeName)) {
                    newTypes.add(typeName);
                    importTypes[typeName] = {
                        id: null,
                        items: new Set()
                    };
                    console.log(`Creating type ${typeName}`);
                } else {
                    console.log(`Type ${typeName} already exists`);
                    // add the type ID to the item
                    typeID = importTypes[typeName].id;
                }

                if (importTypes[typeName].items.has(identifier)) {
                    errorDisplay.innerHTML += `<p style="margin: auto; color: orange;">Row ${i + 1}: "${identifier}" already exists within type "${typeName}".<strong> This item will not be created.</strong></p>`;
                } else {
                    importTypes[typeName].items.add(identifier);
                    newItems.push({ identifier, typeName, typeID, serialNumber });
                }
            }

            console.log('Items validation complete');
            console.log(`Creating ${newTypes.size} types and ${newItems.length} items`);
            console.log(`newItems: ${JSON.stringify(newItems)}`);

            if (errorDisplay.innerHTML.includes('<p style="margin: auto; color: red;">')) {
                console.error('Found errors in the DOM, failed to validate CSV');
            } else if (newTypes.size === 0 && newItems.length === 0) {
                errorDisplay.innerHTML += '<p style="margin: auto; color: red;">No items or types to create.</p>';
                document.getElementById('create-new-item-btn').setAttribute('disabled', 'true');
            } else {
                const itemText = newItems.length === 1 ? 'item' : 'items';
                const typeText = newTypes.size === 1 ? 'type' : 'types';
                errorDisplay.innerHTML += `<br><p style="margin: auto; color: green;"><strong>CSV successfully validated.<br>${newItems.length} ${itemText} and ${newTypes.size} ${typeText} will be created.</strong></p>`;
                document.getElementById('create-new-item-btn').removeAttribute('disabled');

                // Store validated data in a global variable or local storage for later use in the creation step
                window.validatedImportData = { newTypes: Array.from(newTypes), newItems };
            }

            errorDisplay.setAttribute('aria-busy', 'false');
        };

        const fileInput = document.getElementById('items-csv-import');
        if (fileInput.files.length > 0) {
            const file = fileInput.files[0];
            reader.readAsText(file);
        } else {
            errorDisplay.innerHTML = '';
            errorDisplay.setAttribute('aria-busy', 'false');
        }
    }

    window.deferItemsAutoReload = false;
}

// Create a new ITEM TYPE from the form
export async function createNewItemType() {
    document.getElementById('create-new-item-type-btn').setAttribute('aria-busy', 'true');

    // get the item type name
    const itemTypeName = document.getElementById('new-item-type-name').value;
    const checkboxes = document.querySelectorAll('.kiosk-item-type-checkbox');
    // Filter out the unchecked boxes and map to their values
    const selectedKiosks = Array.from(checkboxes)
        .filter(checkbox => checkbox.checked) // Keep only checked boxes
        .map(checkbox => checkbox.value); // Extract the value attribute
    console.log(`selected kiosks: ${selectedKiosks}`);

    // check if we're renaming or creating a new type
    if (window.renamingItemTypeID) {
        console.log(`renaming item type ${window.renamingItemTypeID}`);

        // check if the name is empty
        if (itemTypeName === '') {
            // no error code, just a polite notice..!
            showError('', 'Rename Item Type', 'Please enter a name for the item type.', '', false, false);
            return;
        }

        // check if the name is already taken
        const itemTypesRef = collection(db, 'domains', window.userDomain, 'itemTypes');
        const itemTypesSnapshot = await getDocs(itemTypesRef);

        itemTypesSnapshot.forEach((docu) => {
            // get the data
            const data = docu.data();
            const name = data.name;

            // check for identical name, unless the kiosks have also changed
            if (name === itemTypeName && data.kiosks.length === selectedKiosks.length) {
                showError('', 'Rename Item Type', 'An item type with that name already exists.', '', false, false);
                return;
            }
        });

        // update the item type document
        await updateDoc(doc(db, 'domains', window.userDomain, 'itemTypes', window.renamingItemTypeID), {
            name: itemTypeName,
            kiosks: selectedKiosks,
            // kiosk is true if there are any kiosks selected
            kiosk: selectedKiosks.length > 0
        });

        window.renamingItemTypeID = null;
        console.log('item type renamed!');
        await loadItems(); // full reload of items - types may have changed
    }

    // if we're creating a new type
    else {

        // check if the name is empty
        if (itemTypeName === '') {
            // no error code, just a polite notice..!
            showError('', 'Create Item Type', 'Please enter a name for the item type.', '', false, false);
            return;
        }

        // check if the name is already taken
        const itemTypesRef = collection(db, 'domains', window.userDomain, 'itemTypes');
        const itemTypesSnapshot = await getDocs(itemTypesRef);

        itemTypesSnapshot.forEach((docu) => {
            // get the data
            const data = docu.data();
            const name = data.name;

            // check for identical name, unless the kiosks have also changed
            if (name === itemTypeName && data.kiosks.length === selectedKiosks.length) {
                showError('', 'Create Item Type', 'An item type with that name already exists.', '', false, false);
                return;
            }
        });

        // create a doc at /domains/{window.userDomain}/itemTypes
        const newItemType = {
            name: itemTypeName,
            kiosks: selectedKiosks,
            kiosk: selectedKiosks.length > 0,
            limit: 1, // default limit
            deleted: false
        };

        try {
            await addDoc(itemTypesRef, newItemType);
        } catch (error) {
            console.error('Error creating item type:', error);
            showError('2018', 'Create Item Type', 'There was an error creating the item type. Please try again.', error, false, false);
            return;
        }

        console.log('item type created!');
        // don't reload the items as there cannot be any items of this new type

    }

    // reload the item types. no matter if rename/new
    await loadItemFilters();

    document.getElementById('create-new-item-type-btn').setAttribute('aria-busy', 'false');
    closeModal(document.getElementById('new-item-type-modal'));
}

// load the kiosks into the item type checkboxes
// the types to show in this kiosk
// if editing, check the kiosks that are already assigned to the item type
export async function loadKiosksInItemType(edit = false) {
    console.log('loading kiosks in item type');

    const allKiosks = await loadKiosks(true);

    // clear the dropdown
    document.getElementById('new-item-type-assigned-kiosks').innerHTML = '';

    if (edit) {
        console.log(`editing item type ${window.renamingItemTypeID}`);
        const itemTypeRef = doc(db, 'domains', window.userDomain, 'itemTypes', window.renamingItemTypeID);
        const itemTypeDoc = await getDoc(itemTypeRef);
        const itemTypeData = itemTypeDoc.data();
        const checkedKiosks = itemTypeData.kiosks ? itemTypeData.kiosks : [];
        createDropdown(allKiosks, 'kiosk', checkedKiosks);
    } else {
        createDropdown(allKiosks, 'kiosk');
    }
}

//// TODO: make this a general function across the app
function createDropdown(array, prefix, checked = []) {
    console.log(`array: ${array}`);
    console.log(`prefix: ${prefix}`);
    console.log(`checked: ${checked}`);
    // Find the container where the dropdown will be placed
    const container = document.getElementById('new-item-type-assigned-kiosks');

    // Create the <details> and <summary> elements
    const details = document.createElement('details');
    const summary = document.createElement('summary');
    details.appendChild(summary);
    details.setAttribute('open', 'open');

    // Iterate over the array to create checkboxes
    array.forEach((item) => {
        const kioskName = item.name;
        const kioskID = item.id;
        // Create a checkbox for each item
        const label = document.createElement('label');
        const checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.id = `${prefix}-item-type-${kioskID}`;
        checkbox.value = kioskID;
        checkbox.className = `${prefix}-item-type-checkbox`;
        label.setAttribute('for', `${prefix}-item-type-${kioskID}`);
        label.textContent = kioskName;
        label.insertBefore(checkbox, label.firstChild);
        if (checked && checked.includes(kioskID)) {
            console.log(`${prefix} is checked`);
            checkbox.checked = true;
        }

        // Append the checkbox and label to the <details>
        details.appendChild(label);
    });

    // Append the <details> to the container
    container.appendChild(details);
}

// fill the item type dropdown
export async function fillNewItemFormTypeDropdown() {
    // get the item type dropdown
    const itemTypeDropdown = document.getElementById('new-item-type-options');

    // clear the dropdown
    itemTypeDropdown.innerHTML = '';

    // get each itemTypes document
    const itemTypesRef = query(collection(db, 'domains', window.userDomain, 'itemTypes'), where('deleted', '==', false));
    const itemTypesSnapshot = await getDocs(itemTypesRef);

    itemTypesSnapshot.forEach((docu) => {
        // get the data
        const data = docu.data();
        const name = data.name;
        const ID = docu.id;

        // create the li
        const li = document.createElement('li');
        li.id = ID;

        li.innerHTML = `
        <label>
            <input type="radio" id="new-item-type-radio-${ID}" name="itemType"
                value="${name}">
            ${name}
        </label>
        `;
        itemTypeDropdown.appendChild(li);

        // add event listener for button
        document.getElementById(`new-item-type-radio-${ID}`).addEventListener('mousedown', async () => {
            console.log(`radio button for item type ${ID} has been clicked`);
            const selectedItemTypeName = document.getElementById(`new-item-type-radio-${ID}`).value;
            document.getElementById('new-item-type').innerHTML = selectedItemTypeName;
            document.getElementById('new-item-type').value = ID;
            // set the colour
            document.getElementById('new-item-type').style.color = getComputedStyle(document.documentElement).getPropertyValue('--color');
            console.log(`selected item type: ${ID}, name: ${selectedItemTypeName}`);
        });
    });
}

// create a new item - FORM OR CSV
export async function createNewItem() {
    document.getElementById('create-new-item-btn').setAttribute('aria-busy', 'true');
    window.deferItemsAutoReload = true;

    const validatedData = window.validatedImportData;

    if (validatedData) {
        console.log('Importing from CSV');
        const { newTypes, newItems } = validatedData;
        console.log(`new types: ${JSON.stringify(newTypes)}`);
        console.log(`new items: ${JSON.stringify(newItems)}`);
        const importTypes = [];

        try {
            // Create new types first
            for (const typeName of newTypes) {
                const newItemType = {
                    name: typeName,
                    kiosk: true,
                    limit: 1,
                    deleted: false
                };

                const newTypeDoc = await addDoc(collection(db, 'domains', window.userDomain, 'itemTypes'), newItemType);
                newItems.forEach(item => {
                    if (item.typeName === typeName) {
                        item.typeID = newTypeDoc.id;
                    }
                });
                importTypes.push({
                    name: typeName,
                    id: newTypeDoc.id
                });
                console.log(`Created new type ${typeName} with ID ${newTypeDoc.id}`);
            }

            // Create new items
            for (const item of newItems) {
                // find the type if item.typeID is null
                if (item.typeID === null) {
                    const typeRef = query(collection(db, 'domains', window.userDomain, 'itemTypes'), where('name', '==', item.typeName));
                    const typeSnapshot = await getDocs(typeRef);
                    if (typeSnapshot.size === 0) {
                        console.error(`Type ${item.typeName} not found`);
                        continue;
                    }
                    item.typeID = typeSnapshot.docs[0].id;
                }

                console.log('item:', item);

                const itemRef = collection(db, 'domains', window.userDomain, 'items');
                const newItem = {
                    identifier: item.identifier,
                    serialNumber: item.serialNumber,
                    loaned: false,
                    status: 'active',
                    type: item.typeID,
                    deleted: false
                };
                console.log('newItem:', newItem);

                const newItemDBRef = await addDoc(itemRef, newItem);
                console.log(`Created item ${item.identifier} (${item.typeName})`);

                const newHistoryDocData = {
                    action: 'created',
                    item: newItemDBRef.id,
                    student: null,
                    timestamp: Timestamp.now(),
                    admin: window.userID,
                    archived: false
                };
                console.log('newHistoryDocData:', newHistoryDocData);
                // create a history record for 'created'
                const historyRef = collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'itemHistory');
                await addDoc(historyRef, newHistoryDocData);

                console.log('Created item history record');
            }

            await loadItems();
            await applyItemFilters();
            await delay(200);

            document.getElementById('create-new-item-btn').setAttribute('aria-busy', 'false');
            closeModal(document.getElementById('new-item-modal'));
            window.deferItemsAutoReload = false;
            window.validatedImportData = null; // clear so that manual data entry can be used

            console.log('Items created successfully');
        } catch (error) {
            console.error('Error creating items or types:', error);
            document.getElementById('create-new-item-btn').setAttribute('aria-busy', 'false');
            showError('2019', 'Item Identifier', 'There was an error creating the item. Please try again.', error, false, false);
        }
    } else {
        console.log('No validated data available - assuming a manual data entry');

        // get the item identifier, type, status
        const itemIdentifier = document.getElementById('new-item-identifier').value;
        const itemType = document.getElementById('new-item-type').value;
        var itemSerialNumber = document.getElementById('new-item-serialNumber').value;


        /// TODO: CHECK IF THE IDENTIFIER EXISTS WITHIN THE TYPE OR NOT

        // true = active, false = inactive
        var itemStatus = 'inactive';
        if (document.getElementById('new-item-active').checked) {
            itemStatus = 'active';
        }

        // check if the identifier and type are empty
        if (itemIdentifier === '' || itemType === '') {
            document.getElementById('create-new-item-btn').setAttribute('aria-busy', 'false');
            showError('', 'Item Identifier', 'Please enter an identifier and type.', '', false, false);
            return;
        }

        // serial number is optional
        if (itemSerialNumber === '') {
            itemSerialNumber = null;
        }

        console.log(`valid item: ${itemIdentifier}, ${itemType}, ${itemStatus}, ${itemSerialNumber}`);
        var newItemDBRef = null;

        // create the item
        const itemRef = collection(db, 'domains', window.userDomain, 'items');
        const newItem = {
            identifier: itemIdentifier,
            serialNumber: itemSerialNumber,
            lastLoaned: null,
            lastReturned: null,
            loaned: false,
            loanedTo: null,
            status: itemStatus,
            type: itemType,
            deleted: false
        };

        try {
            newItemDBRef = await addDoc(itemRef, newItem);
        } catch (error) {
            console.error('Error creating item:', error);
            document.getElementById('create-new-item-btn').setAttribute('aria-busy', 'false');
            showError('2019', 'Item Identifier', 'There was an error creating the item. Please try again.', error, false, false);
            return;
        }

        console.log('item created!');

        // create a history record for 'created'
        const historyRef = collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'itemHistory');
        await addDoc(historyRef, {
            action: 'created',
            item: newItemDBRef.id,
            student: null,
            timestamp: Timestamp.now(),
            admin: window.userID,
            archived: false
        });
        console.log('Created item history record');

        // reload the items manager table
        await loadItems();
        // apply the filters
        await applyItemFilters();

        // wait 200ms
        await delay(200);
        document.getElementById('create-new-item-btn').setAttribute('aria-busy', 'false');
        // close the modal
        closeModal(document.getElementById('new-item-modal'));
        window.deferItemsAutoReload = false;

    }
}


// put all the item types into a filter
async function loadItemFilters() {
    console.log('loading item filters');

    const itemTypeDropdown = document.getElementById('item-type-dropdown');
    itemTypeDropdown.innerHTML = '';

    // Add 'show all' option
    var li = document.createElement('li');
    var label = document.createElement('label');
    var input = document.createElement('input');
    input.type = 'radio';
    input.id = 'items-show-all';
    input.name = 'size';
    input.value = 'Show All';
    label.htmlFor = 'items-show-all';
    label.appendChild(input);
    label.appendChild(document.createTextNode('Show All'));
    // Set as checked
    input.checked = true;
    li.appendChild(label);
    itemTypeDropdown.appendChild(li);

    // Get all items
    const q = query(collection(db, 'domains', window.userDomain, 'itemTypes'), where('deleted', '==', false));
    const querySnapshot = await getDocs(q);

    var types = [];

    querySnapshot.forEach((doc) => {
        // Get data from doc
        const data = doc.data();
        // Get type
        types.push({ id: doc.id, name: data.name, kiosk: data.kiosk });
    });

    // Remove duplicates
    types = types.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i);

    // Sort alphabetically
    types.sort((a, b) => a.name.localeCompare(b.name));

    // Remove blank types
    types = types.filter(function (el) {
        return el.name !== '' && el.name != null && el.name !== undefined;
    });

    // Add to dropdown
    types.forEach((type) => {
        var li = document.createElement('li');
        var div = document.createElement('div');
        var label = document.createElement('label');
        var input = document.createElement('input');
        var renameButton = document.createElement('a');
        var deleteButton = document.createElement('a');

        li.style.display = 'flex';
        li.style.alignItems = 'center';
        div.style.flexGrow = '1';

        input.type = 'radio';
        input.id = type.id;
        input.name = 'size';
        input.value = type.name;

        label.htmlFor = type.id;
        label.style.flexGrow = '1';
        label.appendChild(input);
        label.appendChild(document.createTextNode(type.name));

        // Create and style rename button
        renameButton.innerText = 'Edit';
        renameButton.className = 'rename-button';
        renameButton.style.marginLeft = '10px';
        renameButton.href = '#';

        // Create and style delete button
        deleteButton.innerText = 'Delete';
        deleteButton.className = 'delete-button';
        deleteButton.style.marginLeft = '10px';
        deleteButton.style.color = 'red';
        deleteButton.href = '#';

        div.appendChild(label);
        li.appendChild(div);
        li.appendChild(renameButton);
        li.appendChild(deleteButton);
        itemTypeDropdown.appendChild(li);

        // Add event listener for rename button
        renameButton.addEventListener('mousedown', async () => {
            console.log(`Renaming item type ${type.name}`);
            window.renamingItemTypeID = type.id;
            document.getElementById('new-item-type-title').innerText = 'Rename Item Type';
            document.getElementById('create-new-item-type-btn').innerHTML = 'Rename';
            document.getElementById('new-item-type-name').value = type.name;
            await loadKiosksInItemType(true);
            openModal(document.getElementById('new-item-type-modal'));
            await delay(500);
            document.getElementById('new-item-type-name').focus();
        });

        // Add event listener for delete button
        deleteButton.addEventListener('mousedown', async () => {
            console.log(`Deleting item type ${type.name}`);

            window.deletingObjectID = type.id;
            window.deletingObjectType = 'itemType';
            document.getElementById('confirm-delete-message').innerHTML = 'This type, <strong>and any items of this type</strong>, will be permanently deleted.';
            // show the delete confirm modal
            openModal(document.getElementById('confirm-delete-modal'));
        });
    });
}

// run when we delete a type
// backend does history, but can't wait for that to do items due to the time it takes
// we need items to be deleted immediately so they stop showing in the table
export async function deleteItemsForType(typeID) {
    console.log(`deleting items for type ${typeID}`);

    // get the items for this type
    const itemRef = query(collection(db, 'domains', window.userDomain, 'items'), where('type', '==', typeID));
    const itemSnapshot = await getDocs(itemRef);

    // delete each item
    itemSnapshot.forEach(async (doc) => {
        // if this item is loaned, first return it
        if (doc.data().loaned) {
            await returnItem(doc.id);
        }
        await updateDoc(doc.ref, {
            deleted: true
        });
        console.log(`deleted item ${doc.id}`);
        // create a history record for 'deleted'
        const historyRef = collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'itemHistory');
        await addDoc(historyRef, {
            action: 'deleted',
            item: doc.id,
            student: null,
            timestamp: Timestamp.now(),
            admin: window.userID,
            archived: false
        });
    });

    console.log(`deleted all items for type ${typeID}`);
}

export async function processManualLoan() {
    console.log('processManualLoan called');
    const noteElement = document.getElementById('loan-item-admin-note');
    const note = noteElement.value || ''; // note can be empty
    console.log('note:', note);

    const itemID = loaningItemID;
    console.log(`Processing loaning of item ${itemID} to admin`);

    // use this as a static time across all DB calls
    const now = Timestamp.now();

    // load the item data
    const itemDocRef = doc(db, 'domains', window.userDomain, 'items', itemID);
    const itemDocSnap = await getDoc(itemDocRef);
    const itemData = itemDocSnap.data();

    // if the item is not found, error
    if (itemData === null) {
        console.error('Item not found');
        await showError(`7201-${itemID}`, 'There was an error.', 'This item could not be found. Please try again.', null, false, true);
        return;
    }

    // if the item is loaned, error
    if (itemData.loaned) {
        console.error('Item is loaned');
        await showError(`7202-${itemID}`, 'This item is already loaned.', 'This item is already loaned. Please return it and try again.', null, false, true);
        return;
    }

    // update the item document
    await updateDoc(itemDocRef, {
        loaned: true,
        loanedTo: null, // not a student
        lastLoaned: now,
        lastReturned: null
    });

    // add a item history entry
    await addDoc(collection(db, 'domains', window.userDomain, 'intervals', window.currentInterval, 'itemHistory'), {
        item: itemID,
        action: 'loaned',
        student: null,
        timestamp: now,
        kiosk: null,
        archived: false,
        type: 'admin',
        note: note,
        admin: window.userID
    });

    await refreshRow(itemID);
    // apply the filters
    await applyItemFilters();

    loaningItemID = null;
    loanStudentData = [];

    closeModal(document.getElementById('loan-item'));

    await delay(500); // wait for item selection to close

    // clear the note
    document.getElementById('loan-item-admin-note').value = '';


    // back to table
    document.getElementById('loan-item-table').style.display = 'table';
    document.getElementById('loan-item-search').style.display = 'block';
    document.getElementById('loan-item-admin-note').style.display = 'none';
    // change the button to go back to the admin note
    document.getElementById('loan-item-admin-button').innerText = 'Enter custom note...';
    // hide the save button
    document.getElementById('loan-item-admin-save').style.display = 'none';
    document.getElementById('loan-item-title').innerText = 'Select Student';
    window.loanItemAdminNote = false;

}